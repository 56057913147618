<style>
/* .apple-business-chat-banner-container iframe { */
/* border-radius: 4px; */
/* } */
</style>
<template>
  <div>
    <!-- <div
      class="apple-business-chat-banner-container"
      data-apple-business-id=""
      data-apple-business-phone="+447908171250"
      data-apple-banner-cta="Questions? We can help."
      data-apple-banner-context="Chat with us if you have an issue with our venue or website."
      data-apple-banner-rounded-corners="true"
    ></div>-->
    <div
      class="apple-business-chat-banner-container"
      data-apple-business-id="ca0db090 <truncated>"
      data-apple-business-phone="+18005551212"
      data-apple-banner-cta="Questions? We can help."
      data-apple-banner-context="Chat with one of our pros to get your offer for iPhone Xs."
      data-apple-banner-rounded-corners="false"
    ></div>test
  </div>
</template>

<script>
import { getDarkMode } from '@assets/js/darkMode';
import { ContentLoader } from "vue-content-loader"
var loadScripts = require('load-script')

export default {
  methods: {
    initPage() {
      console.log('loaded')
    },
    checkIfBusinessChatIsSupported() {
      if (!window.appleBusinessChat.isSupported()) {
        console.log("Business Chat is not supported");
      } else {
        console.log("Business Chat is supported");
      }
    }
  },
  mounted() {
    this.checkIfBusinessChatIsSupported()
    loadScripts('https://code-eu1.jivosite.com/widget/DsjQU1ggqs', console.log('loaded'));
  },
}
</script>
